import Navbar from '../NavBar/NavBar';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './index.css'

function AboutUs() {

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;

            if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 200) {
                // User is scrolling down
                setShowNavbar(false);
            } else {
                // User is scrolling up
                setShowNavbar(true);
            }

            // Set the new scroll position
            setLastScrollPosition(currentScrollPosition);
        };

        // Attach the scroll listener
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove the scroll listener when the component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollPosition]);
    return (
        <div className='aboutUsContainer'>
            <Helmet>
            <title>تعرف على شركة الوائن</title>
                <meta name="description"
                content="تعرف على شركة البوائن الرائدة في تقديم خدمات نظافة وصانة ومكافة الحرشات"
                />
            </Helmet>
            <Navbar show={showNavbar}/>
          <div className='RequestHeaderImageContainer'>
                
                <img style={{opacity:0.5}} src="/nav-background-Header.jpg" alt="Background" className="RequestHeaderImage" />
                <p className='RequestServicesPageTtitle'>من نحن</p>
            </div>
            <div className='aboutUsBody'>
                <div className='aboutUsText'>
                    <h3>من نحن</h3>
                    <h1 className='aboutUsTitle'>شركة البوائن للنظافة و الصيانة ومكافحة الحشرات</h1>
                    <h3 className='aboutUsDescription'>نحن شركة البوائن لخدمات النظافة العامة,  تنظيف منازل, تنظيف قصور, تنظيف مجالس وسجاد, مكافحة حشرات, تنظيف وعزل خزانات, تنظيف مسابح, تركيب وصيانة مكيفات, نقل وتخزين عفش, تنظيف واجهات, غسيل وصيانة افران
نعمل في السوق البحريني, لدينا خبرة ودراية كبيرة في جميع تخصصاتنا ولدينا فرق عمل مدربه علي اعلي مستوى لتقديم خدمة مميزة تليق بعملائنا الاعزاء</h3>
                </div>
                <div className='aboutUsImageContainer'>
                <img className='aboutUsImage' src='/albwaienLogo.png' alt="Background"/>
                </div>
            </div>

            <footer>
            
            <div className="footer-content">
            <img className="foorterLogo" src='/albwaienLogo.png' alt='logo'/>
                &copy; 2024 Albwaien. All rights reserved.
            </div>
        </footer>
        </div>
    );
}

export default AboutUs;