import React from 'react';
import './Contact.css'
/** Components */
const Card = props => (
    <div className="card   contactCard">
      {props.children}
    </div>
  );
  
  const Form = props => (
    <form className="form  ">{props.children}</form>
  );
  
  const TextInput = props => (
    <div
      className="text-input  ">
      <label
        className={(props.focus || props.value !== '') ? 'label-focus   labelContact' : '  labelContact'}
        htmlFor={props.name}>{props.label}</label>
      <input
        className={(props.focus || props.value !== '') ? 'input-focus  ' : ' '}
        type="text"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onInput={props.onInput}
        onFocus={props.onFocus}
        onBlur={props.onBlur} />
    </div>
  );
  
  const TextArea = props => (
    <div
      className="text-area  ">
      <label
        className={(props.focus || props.value !== '') ? 'label-focus   labelContact' : '  labelContact'}
        htmlFor={props.name}>{props.label}</label>
      <textarea
        className={(props.focus || props.value !== '') ? 'input-focus  ' : ' '}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onInput={props.onInput}
        onFocus={props.onFocus}
        onBlur={props.onBlur} />
    </div>
  );
  
  /** Root Component */
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        name: {
          name: 'name',
          label: 'الاسم',
          value: '',
          focus: false,
        },
        email: {
          name: 'email',
          label: 'رقم الهاتف',
          value: '',
          focus: false,
        },
        message: {
          name: 'message',
          label: 'محتوى الرسالة',
          value: '',
          focus: false,
        },
        messageStatus: " ",
        isSending: false
      }
    }

    
    
    handleFocus(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.focus = true;
      this.setState({ [name]: state },()=>{console.log(state)});
    }
    
    handleBlur(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.focus = false;
      this.setState({ [name]: state },()=>{console.log(state)});
    }
    
    handleChange(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.value = e.target.value;
      this.setState({ [name]: state },()=>{console.log(state)});
    }

    validateInput(name, email, message) {
      // Check for input length
      if (name.length > 100 || email.length > 100 || message.length > 1000) {
          alert("Input is too long.");
          return false;
      }
  
      // Regular expression to allow letters, numbers, and basic punctuation
      let regex = /^[a-zA-Z0-9 .,?!'"]+$/;
  
      if (!regex.test(name) || !regex.test(email) || !regex.test(message)) {
          alert("Input contains invalid characters.");
          return false;
      }
  
      return true;
  }

  sendContactInfo=async(name, email, message)=>{
    if(this.state.isSending){
      return
    }
    if (!name.trim() || !email.trim() || !message.trim()) {
      console.log("cant send")
      this.setState({messageStatus: "ارجو تعبئة كافة الحقول"})
      return;
    }
  
    const text = name + ' :الاسم' + "\n" + email + ' :الايميل' + "\n" + message
    console.log("the text: " + text)

    if(text > 1000){
      return
    }
    this.setState({messageStatus: " "})
    this.setState({isSending: true})
    // Send the data to the server
    fetch('/api/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            subject: "طلب تواصل",
            text: text
        }),
    })
    .then(response => response.text())
    .then(data => {
      this.setState({messageStatus: "تم الارسال بنجاح"})
      this.setState({isSending: false})
        // Reset the form after sending
    })
    .catch((error) => {
      this.setState({messageStatus: "فشل الارسال، ارجو المحاولة مره اخرى"})
      this.setState({isSending: false})
        console.error('Error:', error);
    });
  }

  handleSubmit = (event) => {
  console.log("send")
  event.preventDefault();
    //this.sendContactInfo(this.state.name.value,this.state.email.value,this.state.message.value)
    
  };

    
    render() {
      const {name, email, message} = this.state;
      return (
        <div id='ContactSection' className="constactContainer  ">
            <div className='infoContainer'>
                <img className='infoBackgroundImage' src="/albwaienLogo.png" alt="Background" />
                <div className='contactInforContainer'>
                    <p className='contactInforLocation'>البحرين</p>
                    <ul>
                    <li className='contactInforText'>هاتف : 97332101550</li>
                    <li className='contactInforText'>البريد الاكتروني: care@albwaienbh.com</li>
                    </ul>
                    <p className='contactInforLocation'> السعودية</p>
                    <ul>
                    <li className='contactInforText'>هاتف : 966570303624</li>
                    <li className='contactInforText'>البريد الاكتروني: care@albwaien.com</li>
                    </ul>
                  
                </div>
            </div>
            
          <Card>
            <h1 className='h1Constact'>تواصل معنا</h1>
            <form onSubmit={this.handleSubmit} className="form">
              <TextInput
                {...name}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
                onChange={this.handleChange.bind(this)} />
              <TextInput
                {...email}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
                onChange={this.handleChange.bind(this)} />
              <TextArea
                {...message}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
                onChange={this.handleChange.bind(this)} />
              <div className='messageStatus'>{this.state.messageStatus}</div>
              <button  onClick={()=>{this.sendContactInfo(this.state.name.value, this.state.email.value, this.state.message.value)}} className="sendButton">{this.state.isSending ? '...يتم الارسال' : 'ارسل'}</button>
            </form>
          </Card>
        </div>
      );
    }
  }

  export default App