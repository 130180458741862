import React from 'react';
import './Advantages.css';

function LogoBlock({ logoSrc, description }) {
    return (
        <div className="logo-block">
            <div className='logoContainer'>
                <img src={logoSrc} alt="Logo" className="logo" />
            </div>
            <h2 className='descriptionAdvantage'>{description}</h2>
        </div>
    );
}

function LogoBlockRow() {
    const blocks = [
        { desc: "اجود المواد و المنظفات حفاظا على صحتكم", logo: "/cleaning-products.png" },
        { desc: "احدث الاآجهزة لاعطاء افضل نتيجة باسرع توقيت", logo: "/man-vacuum.png" },
        { desc: "فريق عمل احترافي ذو خبرة كبيرة في مجال عمله", logo: "/cleaning-service.png" }
    ];

    return (
        <div className="logo-block-row">
            {blocks.map((block, index) => (
                <LogoBlock key={index} logoSrc={block.logo} description={block.desc} />
            ))}
        </div>
    );
}

export default LogoBlockRow;