
import Navbar from '../NavBar/NavBar';
import ServiceCard from './ServiceCard';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.css'


function Services() {

    let services = [
        {
            title: "النظافة",
            id: "cleaning",
            serviceRef: useRef(null),
            descriptions: [
                {
                    title: "الآرضيات",
                    description: "نقوم بتنظيف الارضيات بافضل المواد الامنة على صحتكم لاعطاء رائحة عطرة منظر جميل "
                },
                {
                    title: "ازالة الدهون الصعبة من جدران السيرااميك",
                    description: "نقوم بتنظيف جدران السيراميك بافضل المواد المناسبة لابقائها على بريقها ونقوم بازالة الدهون الصعبة ان وجدت"
                },
                {
                    title: "الكنب",
                    description: "تنظبف الصوف بالات البخار الحديثة لارجاعه الى وضعها السابق"
                },
                {
                    title: "الستائر",
                    description: "نقوم بتنظيف الستائر عن طريق الات البخار الحديثة واخراجها بحاله جديدة"
                },
                {
                    title: "السجاد",
                    description: "ازالة جميع البقع و الاوساخ من السجاد باستخدام اجود انواع المواد و الاجهزة"
                },
                {
                    title: "مابعد الإنشاء",
                    description: "نقوم بتنظيف المباني بعد الانشاء باستخدام اجود المواد و الاجهزة"
                },
            ]
        },
        {
            title: "مكافحة الحشرات",
            id: "pestControl",
            serviceRef: useRef(null),
            descriptions: [
                {
                    title: "مكافحة الحشرات والقوارض",
                    description: "نقوم بوضع خطه علاجية للقضاء على الحشرات والقوارض والتاكد من خلو المحيط منها"
                },
                {
                    title: "مكافحة افات النبتات",
                    description: "نقوم بالااعتناء مزارعكم لضمان نموها بشكلها الطبيعي و نعالج سوسة النخيل"
                },
            ]
        },
        {
            title: "الصيانة",
            id: "maintenance",
            serviceRef: useRef(null),
            descriptions: [
                {
                    title: "اعمال الكرباء",
                    description: "نقوم بصاينة اعمال الكرباء في المباني بايدي خبيرة مرخصة مهنيا"
                },
                {
                    title: "اعمال التكييف",
                    description: "لدينا مختصين محترفين بجميع انواع التكييف مدربين على افضل طرق الفحص والصيانة"
                },
                {
                    title: "اعمال السباكة",
                    description: "نقوم بصاينة اعمال السباكة في المباني بايدي خبيرة وبمواد صحية صديقة للبيئة"
                }
            ]
        },
        {
            title: "تنسيق الحدائق",
            id: "landscaping",
            serviceRef: useRef(null),
            descriptions: [
                {
                    title: "التنسيق",
                    description: "تنسيق الحدائق و المساحات الخضراء لتعزيز جمالية المكان و اناقته"
                },
                {
                    title: "الرعاية",
                    description: "نقوم برعاية الحدئق بافضل المواد لضمان صحتها ونموها"
                },
                {
                    title: "الاستدامه",
                    description: "نقوم بوضع خطة رعاية للحدائق لتكون بافضل مظهر دائما"
                }
            ]
        }
    ]

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);

    const location = useLocation();

    useEffect(()=>{
            if(location.state && location.state.goToService){
                services.forEach(service => {
                    if(service.id === location.state.goToService){
                        service.serviceRef.current?.scrollIntoView();
                    }
                })
            }
            else{
                window.scrollTo({top:0})
            }
      
    },[])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;

            if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 200) {
                // User is scrolling down
                setShowNavbar(false);
            } else {
                // User is scrolling up
                setShowNavbar(true);
            }

            // Set the new scroll position
            setLastScrollPosition(currentScrollPosition);
        };

        // Attach the scroll listener
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove the scroll listener when the component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollPosition]);

    return (
        <div className='ServicesPageContainer'>
             <Helmet>
             <title>تعرف على خدماتنا في البواىن لآفضل جودة باقل سعر</title>
            <title>تعرف على شركة الوائن</title>
                <meta name="description"
                content="اعرف اكثر عن خدمات البوائن المتنوعة. لدينا حزم متنوعة ذات جودة فائقة في النظافة والصاينة واكثر" 
                />
            </Helmet>
            <Navbar show={showNavbar}/>
            <div className='headerImageContainer'>
                
                <img style={{opacity:0.5}} src="/nav-background-Header.jpg" alt="Background" className="headerImage" />
                <p className='ServicesPageTtitle'>خدماتنا</p>
            </div>
           
            <div className='ServicesContainer'>
                {services.map((service, index) => (
                    <div key={index} ref={service.serviceRef}>
                    <ServiceCard key={index} title={service.title} descriptions={service.descriptions} id={service.id} />
                    </div>
                ))}
                
            </div>
            <footer>
            
            <div className="footer-content">
            <img className="foorterLogo" src='/albwaienLogo.png' alt='logo'/>
                &copy; 2024 Albwaien. All rights reserved.
            </div>
        </footer>
        </div>
    );
}

export default Services;