import { createBrowserRouter, RouterProvider } from "react-router-dom"
import NavBar from './NavBar/NavBar'
import LandingPgae from './LandingPage/LandingPage'
import Cleanuping from "./Services/index"
import RequestService from './RequestService/index'
import AboutUs from './AboutUs/index'
import './App.css'


const router = createBrowserRouter([
  {
    path:"/",
    children: [
      { index: true, element: <LandingPgae />}
    ]
  },
  {
    path:"/requestService",
    children: [
      { index: true, element: <RequestService />}
    ]
  },
  {
    path:"/services",
    children: [
      { index: true, element: <Cleanuping />}
    ]
  },
  {
    path:"/aboutUs",
    children: [
      { index: true, element: <AboutUs />}
    ]
  },
  {
    path:"*",
    element: <div style={{width: '100hv', height: '100hv', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 36, fontFamily: '-moz-initial'}}>404 Not Found</div>
  },
])

function App() {
  return (
    <div className="App">
     <RouterProvider router={router} />
    </div>
  );
}

export default App;
