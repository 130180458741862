import React from 'react';
import './ServiceCard.css'
import { useNavigate} from 'react-router-dom';


function ServiceCard({title, descriptions, id}) {
    let navigator = useNavigate()
    const routToRequestService =()=>{
       
        navigator("/requestService",  { state: { requestedService: id } })
    }

    return (
        <div className='ServiceCardContainer'>
            <div className='titleContainer'>
                <div className='titleDesign' />
                <h1 className='ServiceTitle'>{title}</h1>
                <div className='titleDesign'/>
            </div>
            <div className='devider'/>
            <div className='descriptionContainer'>
                <ul className='serviceList'>
                    {descriptions.map((description, index) => (
                    
                        <li key={index} className='descItem'>
                        <p className='descTitle'>{description.title}{": "}</p>
                        <p className='descDesc'>{description.description}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='servicePageButtonContainer'>
                <button onClick={routToRequestService} className='servicePageButton'>قدم طلب</button>
            </div>
        </div>
    );
}

export default ServiceCard;