import Navbar from '../NavBar/NavBar';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form"
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.css'


const Field = ({ label, id, register, regName, limit, inpuType, ...rest }) => {
  function validateLength(element, maxLength) {
    if (!maxLength) {
      return
    }
    let value = element.target.value;

    // Check if the value exceeds the maxLength
    if (value.length > maxLength) {
      // If it does, trim the value to the maxLength
      element.target.value = value.slice(0, maxLength);
    }
  }
  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <input id={id} type={inpuType} onInput={e => validateLength(e, limit ?? 0)}  {...rest} {...register(regName)} />
    </div>
  )
}

const Select = ({ label, id, children, register, regName, onChangeF, ...rest }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <select style={{ background: 'white' }} id={id} onInput={e => onChangeF(e.target.value)} {...rest}  {...register(regName)}>
      {children}
    </select>
  </div>
);

function RequestServices() {



  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const { register, handleSubmit, reset, watch } = useForm();
  const [serviceType, setServiceType] = useState("cleaning");
  const [isSending, setIsSending] = useState(false);
  const [messageStatus, setMessageStatus] = useState('');


  const location = useLocation();

  const onSubmit = (values) => {
    console.log(JSON.stringify(values))
    sendContactInfo(values)
  }


  const changeType = (newValue) => {
    console.log(watch('name'))
    let prevValues = {
      "name": watch('name'),
      "phoneNumber": watch('phoneNumber'),
      "location": watch('location'),
      "ServiceType": newValue
    }
    setServiceType(newValue)
    reset()
    reset(prevValues)
  }

  const emptyF = (newValue) => {
    console.log(newValue)
  }

  useEffect(()=>{
    if(location.state && location.state.requestedService){
      changeType(location.state.requestedService)
    }
   

},[])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 200) {
        // User is scrolling down
        setShowNavbar(false);
      } else {
        // User is scrolling up
        setShowNavbar(true);
      }

      // Set the new scroll position
      setLastScrollPosition(currentScrollPosition);
    };

    // Attach the scroll listener
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the scroll listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition]);

  const sendContactInfo = async (json) => {
    if(isSending){
      return
    }

    console.log("the text: " + json.name)
    if (!json.name.trim() || !json.phoneNumber.trim()) {
      setMessageStatus( "ارجو تعبئة كافة الحقول")
      return;
    }

    let text = jsonToString(json)

    if(text.length > 1000){
      return
    }
    setMessageStatus("")
    setIsSending(true)
    // Send the data to the server
    fetch('/api/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject: "طلب خدمة",
        text: text
      }),
    })
      .then(response => response.text())
      .then(data => {
        setMessageStatus("تم الارسال بنجاح")
        setIsSending(false)
        // Reset the form after sending
      })
      .catch((error) => {
        setMessageStatus("فشل الارسال، ارجو المحاولة مره اخرى")
        setIsSending(false)
        console.error('Error:', error);
      });
  }

  const jsonToString = (json) => {
    let result = "";
    for (const [key, value] of Object.entries(json)) {
      let newValue = value.length == 0 ? "0" : value
      result += `${translateJSONKeys(key)}: ${newValue}\n`;
    }
    return result;
  }

  const translateJSONKeys = (key) => {
    switch (key) {
      case "name":
        return "اسم"
      case "phoneNumber":
        return "رقم التواصل"
      case "sofa":
        return "حجم الصوفة"
      case "floor":
        return "امتار الارضية"
      case "ServiceType":
        return "نوع الخدمة"
      case "ceramicWalls":
        return "امتار جدران السيراميك"
      case "curtains":
        return "عدد امتار الستائر"
      case "cerpet":
        return "امتار السجاد"
      case "removeFat":
        return "ازالة ادهون الصعبة"
        case "UnitType":
        return "نوع الوحدة"
      case "ContractType":
        return "نوع العقد"
      case "location":
        return "الموقع"
      case "landscaping":
          return "تنسيق الحدائق"
      default:
        return "مدحل غير معرف"
    }
  }




  return (
    <div className='RequestServicesPageContainer'>
      <Helmet>
      <title>قدم طلب للحصول لافضل خدمة نظافة وصاينة بالبحرين</title>
        <meta name="description"
          content="قدم طلبك اليوم من اي مكان بالبحرين للحصول على خدمات نظافة وصاينة مكافحة حشرات واكثر. البوائن هي الحل الافضل لمكان آمن ونظيف "
        />
      </Helmet>
      <Navbar show={showNavbar} />
      <div className='RequestHeaderImageContainer'>

        <img style={{ opacity: 0.5 }} src="/nav-background-Header.jpg" alt="Background" className="RequestHeaderImage" />
        <p className='RequestServicesPageTtitle'>قدم طلب</p>
      </div>
      <div className='RequestServiceForm'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field label="الاسم" id="name" register={register} regName={'name'} limit={50} inpuType={"text"} />
          <Field label="رقم التواصل" id="phoneNumber" register={register} regName={'phoneNumber'} limit={50} inpuType={"text"} />
          <Select label="الموقع" id="location" register={register} regName={'location'} onChangeF={emptyF}>
            <option value="المنامة">المنامة</option>
            <option value="الرفاع">الرفاع</option>
            <option value="المحرق">المحرق</option>
            <option value="ستره">ستره</option>
            <option value="مدينة عيسى">دينة عيسى</option>
            <option value="مدينة حمد">مدينة حمد</option>
            <option value="مدينة زايد">مدينة زايد</option>
          </Select>
          <Select label="نوع الخدمة" id="ServiceType" register={register} regName={'ServiceType'} onChangeF={changeType}>
            <option value="cleaning">نظافة</option>
            <option value="pestControl">مكافحة حشرات</option>
            <option value="maintenance">صيانة</option>
            <option value="landscaping">تنسيق الحدائق</option>
          </Select>

          {
            serviceType == "cleaning" ?
              <>
                <Field label="حجم الصوفة" id="sofa" register={register} regName={'sofa'} limit={10} inpuType={"number"} />
                <Field label="امتار الارضية" id="floor" register={register} regName={'floor'} limit={10} inpuType={"number"} />
                <Field label="امتار جدران السيراميك" id="ceramicWalls" register={register} regName={'ceramicWalls'} limit={10} inpuType={"number"} />
                <Field label="عدد امتار الستائر" id="curtains" register={register} regName={'curtains'} limit={10} inpuType={"number"} />
                <Field label="امتار السجاد" id="cerpet" register={register} regName={'cerpet'} limit={10} inpuType={"number"} />
                <Select label="ازالة ادهون الصعبة" onChangeF={emptyF} id="removeFat" register={register} regName={'removeFat'} >
                  <option value="لا">لا</option>
                  <option value="نعم">نعم</option>
                </Select>
              </>

              :
              serviceType == "pestControl" ?
                <>
                  <Select label="نوع الوحدة" id="UnitType" onChangeF={emptyF} register={register} regName={'UnitType'} >
                    <option value="عمارة">عمارة</option>
                    <option value="شقة">شقة</option>
                    <option value="فلة">فلة</option>
                    <option value="صالة">صالة</option>
                    <option value="بيت">بيت</option>
                  </Select>
                  <Select label="نوع العقد" id="ContractType" onChangeF={emptyF} register={register} regName={'ContractType'} >
                    <option value="زيارة واحدة">زيارة واحدة</option>
                    <option value="عدة زيارات">عدة زيارات</option>
                  </Select>
                </>
                :
                serviceType == "maintenance" ?
                  <>
                    <Select label="نوع الصاينة" id="maintenanceType" onChangeF={emptyF} register={register} regName={'ContractType'} >
                      <option value="التكيف">التكيف</option>
                      <option value="الكهرباء">الكهرباء</option>
                      <option value="السباكة">السباكة</option>
                    </Select>
                  </>
                  :
                  serviceType == "landscaping" ?
                  <>
                    <Select label="نوع الوحدة" id="UnitType" onChangeF={emptyF} register={register} regName={'UnitType'} >
                    <option value="حديقة">حديقة</option>
                    <option value="مزرعة">مزرعة</option>
                  </Select>
                    <Select label="نوع العقد" id="ContractType" onChangeF={emptyF} register={register} regName={'ContractType'} >
                    <option value="زيارة واحدة">زيارة واحدة</option>
                    <option value="عدة زيارات">عدة زيارات</option>
                  </Select>
                  </>
                  :
                  <></>
          }
          <div className='messageStatus'>{messageStatus}</div>
          <button className='sendButton'>{isSending ?  '...يتم الارسال' : 'ارسل'} </button>
        </form>
      </div>
      <footer>

        <div className="footer-content">
          <img className="foorterLogo" src='/albwaienLogo.png' alt='logo' />
          &copy; 2024 Albwaien. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default RequestServices;



//الاحياء

//المنامة
//الرفاع
//المحرق
//ستره
//دينة عيسى
//مدينة حمد
//مدينة زايد