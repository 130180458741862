import React, {useState, useEffect} from 'react';
import './NavBar.css';
import {Outlet} from "react-router-dom"
import { useNavigate, Link, useLocation } from 'react-router-dom';

function Navbar({show, scrollToContact}) {
    let navigator = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [burgerNav, setBurgerNav] = useState(false);
    const routToLanding =()=>{
        navigator("/")
    }
    const routToContact =()=>{
        if(location.pathname == '/'){
            scrollToContact()
        }
        else{
            navigator("/", {state:{goToContact:true}})
        }
    }

    const routToRequestService =()=>{
       
        navigator("/requestService")
    }

    useEffect(() => {
        function handleResize() {
          if(window.innerWidth <= 850 ){
            setIsOpen(false)
            setBurgerNav(true)
          }
          else{
            setBurgerNav(false)
          }
        }
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    

    return (
        <div className={show? 'navbarShow' :'navbarHide'}>
            <nav className="navbar padingH">
              {burgerNav?
                <>
                <button className="burgerButton" onClick={() => setIsOpen(!isOpen)}>
                <p className='burgerP'>☰</p>
                </button>
                {isOpen && (
                    <div className='burgerContainer'>
                        <ul className="nav-items">
                            <li><Link to="/services">خدماتنا</Link></li>
                            <li><Link to="/aboutUs">من نحن</Link></li>
                            <li> <a onClick={routToContact}>تواصل معنا</a></li>
                            <button onClick={routToRequestService} className='navButton'> اطلب الخدمة </button>
                        </ul> 
                    </div>
                )}
                </>
              :
                <>
                 <button onClick={routToRequestService} className='navButton'> اطلب الخدمة </button>

                <ul className="nav-items">
                    <li><Link to="/services">خدماتنا</Link></li>
                    <li><Link to="/aboutUs">من نحن</Link></li>
                    <li> <a onClick={routToContact}>تواصل معنا</a></li>
                </ul>
                </>
              }
               



                
                <div className='nacLogoContainer'>
                    <img className="navLogo" onClick={routToLanding} src='/albwaienLogo.png' alt='logo'/>
                </div>
            </nav>
            <Outlet />
        </div>
    );
}

export default Navbar;