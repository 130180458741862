import React, { useState, useEffect, useRef } from 'react';
import './LandingPage.css';
import Navbar from '../NavBar/NavBar';
import Brief from './brief'
import Contact from './Contact'
import Advantages from './Advantages'
import { Helmet } from 'react-helmet';
//import ServiceInfo from './ServiceInfo'
import { useNavigate, useLocation } from 'react-router-dom';



function Landing() {
    var toggle = false
    const contactRef = useRef(null);
    let navigator = useNavigate();
    const routToServices = (id) => {
        navigator("\services", { state: { goToService: id } })
    }
    const routToRquestService = () => {
        navigator("/requestService")
    }

    const location = useLocation();

    const scrollToElement = () => {
        contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    const routToService = (id) => {
       
        navigator("/", { state: { goToService: id } })
        
    }

    useEffect(() => {
        if (location.state && location.state.goToContact) {
            scrollToElement()
        }
        else {
            window.scrollTo({ top: 0 })
        }

    }, [])

    window.addEventListener('beforeunload', (event) => {
        console.log("reload")
    });

    let services = [
        {
            title: "خدمات النظافة",
            description: <p className='serviceDescription'>في البوائن، نقدم خدمات نظافة ممتازة تلبي جميع متطلباتكم. فريقنا المتخصص يتميز بدقة فائقة في العمل، مما يكفل نتائج مذهلة تتجاوز توقعاتكم. نحن نولي اهتمامًا كبيرًا بالجودة، مستخدمين أفضل المواد والتقنيات لضمان نظافة وإشراق منزلك أو مكتبك. اخترنا لتجربة فارق خدمتنا الاستثنائية.</p>,
            images: "/cleaning-brief.jpg",
            id: "cleaning",
            rout: routToServices
        },
        {
            title: "مكافحة الحشرات",
            description: <p className='serviceDescription'>في البوائن، نقدم خدمات مكافحة الشحرات بطرق متقدمة وفعالة. فريقنا يضم خبراء متخصصين، ونستعين بأحدث التقنيات والمواد الآمنة لمحاربة كافة أنواع الحشرات. نحن نضع الجودة والسلامة في قمة أولوياتنا، ملتزمين بحماية منازلكم وأعمالكم و مزروعاتكم من أي تهديدات قد تحدثها الحشرات. اختيار شركتنا يعني ضمان بيئة نظيفة وخالية من الآفات.</p>,
            images: "/pest-control.jpg",
            id: "pestControl",
            rout: routToServices
        },
        {
            title: "الصيانة",
            description: <p className='serviceDescription'>اختيار البوئن لصيانة الكهرباء والتكييف يوفر خبرة واسعة وخدمات شاملة. نحن نضمن الكفاءة العالية، السلامة، والاستجابة السريعة، مع التركيز على الحلول المخصصة والتكلفة الفعالة لتلبية جميع احتياجاتكم</p>,
            images: "/maintenance.jpg",
            id: "maintenance",
            rout: routToServices
        },
        {
            title: "تنسيق الحدائق",
            description: <p className='serviceDescription'>اختيار البوائن لأعمال التنسيق الحدائق يضمن لكم خدمات متميزة بتصاميم إبداعية وحلول مبتكرة. نحن نقدم عناية فائقة بكل تفاصيل المساحات الخضراء، مع التزامنا بالجودة العالية والاستجابة السريعة لضمان بيئة خارجية جذابة ومستدامة.</p>,
            images: "/landscaping.jpg",
            id: "landscaping",
            rout: routToServices
        }
    ]

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;

            if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 200) {
                // User is scrolling down
                setShowNavbar(false);
            } else {
                // User is scrolling up
                setShowNavbar(true);
            }

            // Set the new scroll position
            setLastScrollPosition(currentScrollPosition);
        };

        // Attach the scroll listener
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove the scroll listener when the component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollPosition]);

    return (
        <div className='pageStyle'>
            <Helmet>
                <title>خدمات تنظيف وصيانة ومكافحة حشرات محترفة في البحرين | البوائن</title>
                <meta name="description"
                content="اكتشف خدمات التنظيف والصيانة ومكافحة الحشرات الرائدة في البحرين. خدمة شاملة، موثوقة وصديقة للبيئة لمنازلكم ومكاتبكم. لبيئة نظيفة وآمنة"
                />
            </Helmet>
            <div className="landing-container">
                <Navbar scrollToContact={scrollToElement} show={showNavbar} />
                <img style={{ opacity: 0.5 }} src="/cleaning.jpg" alt="Background" className="landing-bg" />

                <div className="landing-content">
                    <h1 className='companyName'>شركة البوائن</h1>
                    <h1 className='landingTitle'>خدمات النظافة الافضل في البحرين</h1>
                    <p className='landingDesc'>نقدم خدماتنا بافضل الكوادر و احدث المعدات و اجود المواد لجميع القطاعات</p>
                    <button className='serviceButton' onClick={routToRquestService}>اطلب الخدمة الان</button>
                </div>
            </div>

            <Advantages />
            {services.map((service, index) => {
                toggle = !toggle
                return <Brief key={index} right={toggle} title={service.title} desc={service.description} image={service.images} rout={service.rout} id={service.id} />
            })}
            <div ref={contactRef} >
                <Contact />
            </div>
            <footer>

                <div className="footer-content">
                    <img className="foorterLogo" src='/albwaienLogo.png' alt='logo' />
                    &copy; 2024 Albwaien. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

export default Landing;