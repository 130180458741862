import React from 'react';
import './brief.css'

function Brief({ right, title, desc, image, rout, id }) {
    return (
        <div style={{justifyContent:'center'}}>
            <div className='titleContainer'>
                <div className='titleDesign' />
                <h1 style={{paddingLeft:'5px', paddingRight: '5px'}}>{title}</h1>
                <div className='titleDesign' />
            </div>
            <div className="briefText">
                <div className={right ? 'briefContainer' : 'briefContainerRev'}>
                    <img src={image} alt="Background" className="briefImage" />

                    {desc}

                </div>
            </div>
            <div className='knowMoreButtonContainer'>
                <button onClick={()=>rout(id)} className='knowMoreButton'>اعرف اكثر</button>
            </div>
        </div>
    );
}

export default Brief;